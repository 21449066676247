// eslint-disable-next-line import/no-cycle
import api from '../boot/axios'
// eslint-disable-next-line import/no-cycle,no-unused-vars
import router from '../router'

export default {
  namespaced: true,
  state: {
    arama: '',
    GorevPlani: [],
    GorevTakvim: [],
    StartDate: null,
    EndDate: null,
    GorevPlan: {
      isim: null,
      aciklama: null,
    },
    GorevPlaniPersonelDetay: { },
    GorevPlaniDetayGrupluPersonel: [],
    GorevPlanlariMusteri: [],
    manuel_guncelle: 0,
  },
  mutations: {
    StartDateTanimla(state, data) {
      state.StartDate = data
    },
    EndDateTanimla(state, data) {
      state.EndDate = data
    },
    GorevPlaniTanimla(state, data) {
      state.GorevPlani = data
    },
    GorevTakvimTanimla(state, data) {
      state.GorevTakvim = data
    },
    GorevPlaniEkle(state, data) {
      state.GorevPlani.push(data)
    },
    GorevPlaniPersonalDetayiGetir(state, data) {
      state.GorevPlaniPersonelDetay = data
    },
    GorevPlaniPersonalGruplanmisDetayGetir(state, data) {
      state.GorevPlaniDetayGrupluPersonel = data
    },
    GorevPlaniMusteriGruplanmisDetayGetir(state, data) {
      state.GorevPlanlariMusteri = data
    },
    setManuelGuncelle(state, data) {
      state.manuel_guncelle = data
    },
  },
  actions: {
    GorevPlaniTakvim({ rootState, state, commit }) {
      const personel = rootState.Personeller.SelectPersonel
      // eslint-disable-next-line no-prototype-builtins
      if (state.StartDate && state.EndDate) {
        api.get('gorevplani/takvim', { params: { from: state.StartDate, to: state.EndDate, personel } }).then(response => {
          if (response?.data?.result) commit('GorevTakvimTanimla', response?.data?.result)
          // eslint-disable-next-line consistent-return
        })
      } else {
        api.get('gorevplani/takvim', { params: { personel } }).then(response => {
          if (response?.data?.result) commit('GorevTakvimTanimla', response?.data?.result)
          if (response?.data?.result) commit('StartDateTanimla', response?.data?.start_date)
          if (response?.data?.result) commit('EndDateTanimla', response?.data?.end_date)
          // eslint-disable-next-line consistent-return
        })
      }
      // eslint-disable-next-line consistent-return
    },

    GorevPlaniListesi({ commit, state }) {
      // eslint-disable-next-line consistent-return
      api.get('gorevplani', { params: { manuel_guncelle: state.manuel_guncelle } }).then(response => {
        // eslint-disable-next-line consistent-return
        if (response?.data?.code === 200) {
          commit('GorevPlaniTanimla', response.data.result)
        }
      })
    },
    GorevPlaniDetayliGetir({ commit }, data) {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        api.post('gorevplani-detayli-getir', data).then(response => {
          resolve(response)
          if (response?.data?.code === 200) {
            commit('GorevPlaniPersonalDetayiGetir', response?.data?.result)
          }
        },
        error => {
          reject(error)
        })
      })
    },

    GorevPlaniMusteri({ commit }, data) {
      return new Promise((resolve, reject) => {
        api.get(`gorevplani-musteri/${data.id}`).then(response => {
          resolve(response)
          if (response?.data?.code === 200) {
            commit('GorevPlaniMusteriGruplanmisDetayGetir', response?.data?.result)
          }
        },
        error => {
          reject(error)
        })
      })
    },

    GorevPlaniDetayPersonel({ commit }, data) {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        api.post('gorevplani-detay-personel', data).then(response => {
          resolve(response)
          if (response?.data?.code === 200) {
            commit('GorevPlaniPersonalGruplanmisDetayGetir', response?.data?.result)
          }
        },
        error => {
          reject(error)
        })
      })
    },

    GorevPlaniOlustur({ commit }, data) {
      // eslint-disable-next-line consistent-return
      api.post('gorevplani', data).then(response => {
        // eslint-disable-next-line consistent-return
        if (response?.data?.code === 201) {
          commit('GorevPlaniEkle', response.data.result)
        }
      })
    },
    GorevPlaniSilTekrarOlustur({ commit }, data) {
      // eslint-disable-next-line consistent-return
      api.post('gorevplani-sil-olustur', data).then(response => {
        // eslint-disable-next-line consistent-return
        if (response?.data?.code === 201) {
          commit('GorevPlaniEkle', response.data.result)
        }
      })
    },

    // eslint-disable-next-line no-empty-pattern
    GorevPlaniGuncelle({ }, data) {
      return new Promise((resolve, reject) => {
        api.patch(`gorevplani/${data.id}`, data)
          .then(response => {
            resolve(response)
          },
          error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    GorevPlaniPersonelGuncelle({ }, data) {
      return new Promise((resolve, reject) => {
        api.post('gorevplani-detayli-guncelle', data).then(response => {
          resolve(response)
        },
        error => {
          reject(error)
        })
      })
    },
    GorevPlaniPersonelSil({ }, data) {
      return new Promise((resolve, reject) => {
        api.post('gorevplani-detayli-personel-sil', data).then(response => {
          resolve(response)
        },
        error => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-empty-pattern
    GorevPlaniSil({ state }, data) {
      return new Promise((resolve, reject) => {
        api.delete(`gorevplani/${data.id}`, data)
          .then(response => {
            if (response.data.status) {
              const index = state.GorevPlani.findIndex(evrak => evrak.id === data.id)
              // eslint-disable-next-line no-unused-vars
              const veri = state.GorevPlani.splice(index, 1)
              resolve(response)
            }
          },
          error => {
            reject(error)
          })
      })
    },
  },
}
