<template>
  <v-menu
    v-model='opened'
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="ms-4">
        <v-avatar
          v-if="user.fotograf"
          size="40px"
          v-bind="attrs"
          class="ms-4"
          v-on="on"
        >
          <v-img :src="user.fotograf"></v-img>
        </v-avatar>
        <v-avatar
          v-else
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <div id="profileImage2">
            {{ varSayilanFirma.adi.substr(0,2) }}
          </div>
        </v-avatar>
      </div>
    </template>
    <v-list>
      <div class="pb-3 pt-2" style='background: lightgray;'>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ $t('layout.secili_firma')}} {{varSayilanFirma.adi}}
          </span>
        </div>
      </div>
      <v-divider class="my-2"></v-divider>
      <!-- Settings -->
      <v-list-item link v-for='firma in Firmalar' :key="'firma_'+firma.id">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiOfficeBuilding }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content @click="varsayilanFirmaSec(firma)">
          <v-list-item-title>{{ firma.adi }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiOfficeBuilding,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiOfficeBuilding,
      },
      opened: false,
      firmaData: {
        id: '',
        adi: '',
        cadde: '',
        postakodu_sehir: '',
        firma_logo: '',
        aug_izin_tarihi: '',
      },
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    varSayilanFirma() {
      return this.$store.state.firma
    },
    Firmalar() {
      return this.$store.state.Firmalar.Firmalar
    },
    fotograf() {
      return this.user.isim.charAt(0) + this.user.soyisim.charAt(0)
    },
  },
  watch: {
    opened: {
      handler() {
        if (this.opened) {
          this.getAllCompanies()
        }
      },
    },
  },
  methods: {
    varsayilanFirmaSec(e) {
      this.firmaData.id = e.id
      this.firmaData.adi = e.adi
      this.firmaData.cadde = e.cadde ? e.cadde : ''
      this.firmaData.postakodu_sehir = e.postakodu_sehir ? e.postakodu_sehir : ''
      this.firmaData.logo = e.firma_logo ? e.firma_logo : ''
      this.firmaData.aug_izin_tarihi = e.aug_izin_tarihi ? e.aug_izin_tarihi : ''

      this.$store.commit('SetVarsayilanFirma', this.firmaData)
    },
    async getAllCompanies() {
      console.log('Companies Loaded.')
      await this.$store.dispatch('Action', { name: 'Firmalar/FirmalarListesi' })
    },
    logout() {
      this.$store.dispatch('Auth/Logout')
    },
    accountEdit() {
      const user = this.$store.state.user
      this.$router.push({ name: 'kullanici-guncelle', params: { id: user.id, user, cancel: false } })
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
#profileImage2 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #6777ef;
  font-size: 18px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin: 0px 0;
}
</style>
