export default {
  EVET: 'EVET',
  HAYIR: 'HAYIR',
  Sun: 'Paz',
  Mon: 'Pts',
  Tue: 'Salı',
  Wed: 'Çar',
  Thu: 'Per',
  Fri: 'Cum',
  Sat: 'Cts',
  January: 'Ocak',
  February: 'Şubat',
  March: 'Mart',
  April: 'Nisan',
  May: 'Mayıs',
  June: 'Haz',
  July: 'Temmuz',
  August: 'Ağustos',
  September: 'Eylül',
  October: 'Ekim',
  November: 'Kasım',
  December: 'Aralık',
  APP_NAME: 'KARO APP',
  APP_COPYRIGHT: 'Copyright 2022 KARO APP',
  UPLOAD_URL: 'http://localhost:8080/upload/',
  BACKEND_URL: 'http://localhost:8080/',
  API_URL: 'http://localhost:8080/',
  SITE_URL: 'http://localhost:8081/',
  turkish: 'Türkçe',
  german: 'Almanca',
  turkish_code: 'TR',
  german_code: 'DE',
  TAMAM: 'Tamam',
  HATA: 'Hata !',
  BASARILI: 'Başarılı!',
  YUKLENIYOR: 'Veriler yükleniyor lütfen bekleyiniz.',
  YOK: ' - ',
  tarih: 'Tarih',
  auth: {
    KULLANICI_ADI_SIFRE_GECERSIZ: 'Kullanıcı adı veya şifre geçersiz.',
    OTURUM_SONLANDI: 'Oturumunuz sonlandı lütfen tekrar giriş yapınız.',
  },
  menu: {
    panel: 'Panel',
    personel: 'Personeller',
    musteri: 'Müsteriler',
    malzeme: 'Malzemeler',
    tanimlar_main: 'Tanımlar',
    belgeler: 'Belgeler',
    tanimlar: {
      ulkeler: 'Ülkeler',
      sehirler: 'Şehir & Posta Kodu',
      sigorta_sirketleri: 'Sigorta Şirketleri',
      evrak_tipleri: 'Evrak Tipleri',
      meslek: 'Meslek Grupları',
      uyruklar: 'Uyruklar',
    },
    kullanicilar_main: 'Kullanıcılar',
    sozlesmeler: 'Sözleşmeler',
    gorev_takvimi: 'Görev Takvimi',
    gorev_plani: 'Görev Planı',
    gorev_mutabakat: 'Görev Mutabakat',
    faturalar: 'Faturalar',
  },
  layout: {
    kullanici: 'Kullanıcı',
    yonetici: 'Yönetici',
    girisyap: 'Giriş Yapın!',
    ayarlar: 'Hesap Ayarları',
    cikis_yap: 'Güvenli Çıkış',
    Ara: '..Sayfada ara..',
    FIRMA_SECIN: 'FİRMA SEÇİN',
    firma_secimi: 'Firma',
    firma_secim_modal_text: 'Firma Seçimi  Ekranı',
    firma_secim_olustur: 'Firma Seçimini Onayla',
    firma_secim_hatasi: 'Firma Seçmelisiniz',
    secili_firma: 'Seçili Firma',
  },
  Faturalar: {
    ExcelFile: 'Faturalar',
    olustur: 'Yeni Fatura Kaydı',
    kaydet: 'Kaydet',
    guncelle: 'Güncelle',
    fatFirmaText: 'Firma',
    fatText: 'Fatura',
    fatura_detaylari: 'Fatura Detayları',
    PDFButtonTooltip: 'Fatura PDF i İçin Tıklayınız',
    DetayButtonTooltip: 'Fatura Detay görüntülemek için tıklayınız.',
    FaturaGuncelleButtonTooltip: 'Fatura güncellemek için tıklayınız.',
    FaturaSilButtonTooltip: 'Fatura silmek için tıklayınız.',
    fatura_no: 'Fat. No',
    fatura_tarihi: 'Fatura Tarihi',
    bitis_tarihi: 'Bitiş Tarihi',
    ara_toplam: 'Ara Toplam:',
    indirim: 'İndirim:',
    vergi: 'Vergi',
    toplam: 'Toplam:',
    not: 'Not',
  },

  GorevPlani: {
    ExcelFile: 'GorevPlani',
    Gorev_Plani: 'Görev Planı',
    Gorev_Takvimi: 'Görev Takvimi',
    tarih_sec: 'İki Tarih aralığı seçiniz.',
    baslangic_tarihi: 'BAŞLANGIÇ TARİHİ',
    olustur: 'Yeni Görev Kaydı',
    kaydet: 'Kaydet',
    islem: 'İŞLEMLER',
    islem_kodu: 'KOD',
    firma: 'FİRMA',
    musteri_adi: 'M. ADI',
    musteri_kod: 'M. KOD',
    cadde_nr: 'M. CADDE',
    postakodu_sehir: 'M. ŞEHİR',
    ulke: 'M. ÜLKE',
    baslama_tarihi: 'BAŞ. TARİHİ',
    baslama_saati: 'BAŞ. SAATİ',
    bitis_tarihi: 'BİT. TARİHİ',
    bitis_saati: 'BİT. SAATİ',
    onay: 'DURUM',
    personel_sayisi: 'PER.',
    adres_id: 'Adres ID',
    musteri_id: 'Müşteri ID',
    personeller: 'Personel ID',
    filtrele: 'Filtrele',
    ADRES_SECIN: 'ADRES SEÇİN',
    MUSTERI_SECIN: 'MUŞTERİ SEÇİN',
    PERSONEL_SECIN: 'PERSONEL SEÇİN',
    otomatik_duzenle: 'GOREV PLANI GUNCELLE',
    evrak_duzenle: 'EVRAK GUNCELLE',
    faal_gorev_plani: 'Firmasının Faal Görev Planları',
    secilen_filtreler: 'Seçilen Filtreler',
    manuel_guncelle: 'Arası Görev Planı Manuel Güncelle',
    Gorev_Mutabakat: 'Görev Mutabakat',
    PLAN_SECIN: 'PLAN SEÇİN',
    MANUEL_ICIN_PLAN: 'Manuel Güncelleme  İçin Plan Seçimi',
    MANUEL_ICIN_PLAN_DETAY: 'Manuel Güncelleme  Plan Detay',
  },
  Dosyalar: {
    id: '#ID',
    evrak: 'Evrak Tipi',
    dosya_adi: 'Dosya Adı',
    dosya_boyutu: 'Dosya Boyutu',
    olusturma_tarihi: 'Yükleme Tarihi',
    sil: 'Sil',
    guncelle: 'Guncelle',
    islem: 'İŞLEMLER',
    dosya_yukle: 'Dosya Yükle',
    olustur: 'Yeni dosya ekleyin',
    kaydet: 'Dosya yükle',
    evrak_tipi: 'Evrak Tipi',
    belge: 'Belge',
    yeni_evrak: 'Yeni evrak tipi eklemek için yazın ve ENTER tuşuna basarak kayıt edin.',
    tumunu_indir: 'Tüm Dosyaları indir (.ZIP)',
    bitis_tarihi: 'Bitiş Tarihi',
    dosya_listesi: 'Belgeler',
    personel_no: 'NO',
    personel_isim: 'İsim',
    personel_soyisim: 'Soyisim',
    evrak_grup: 'Grup',
    ExcelName: 'Dosya Listesi',
    dosya_uzantisi: 'Uzantı',
    evrak_tip_id: 'Evrak ID',
    dosya: 'Dosya',
    dosya_yolu: 'Dosya Yolu',
    status: 'Durum',
    created: 'Oluşturma Tarihi',
  },
  login: {
    hosgeldiniz: 'Hoş geldiniz.',
    giris_yapin: 'Lütfen hesabınıza giriş yapın ve çalışmaya başlayın',
    kullanici_adi: 'Kullanıcı Adı',
    sifre: 'Şifre',
    beni_hatirla: 'Beni hatırla',
    sifremi_unuttum: '',
    yenimisin: '',
    hesap_olustur: '',
    giris: 'GİRİŞ YAPIN',
  },
  kullanicilar: {
    kullanicilar: 'Kullanıcılar',
    kullanici: 'Kullanıcı',
    yonetici: 'Yönetici',
    id: '#ID',
    kullanici_adi: 'Kullanıcı adı',
    isim: 'İsim',
    soyisim: 'Soy İsim',
    yetki: 'Yetki',
    olusturma_tarihi: 'Oluşturma Tarihi',
    islem: 'İşlemler',
    sifre: 'Şifre',
    kaydet: 'Kaydet',
    sifirla: 'Sıfırla',
    iptal: 'İptal',
    durum: 'Kullanıcı durumu',
    aktif: 'Kullanıcı Aktif',
    pasif: 'Kullanıcı Devredışı',
    kullanici_olustur: 'Yeni kullanıcı kaydı.',
    ExcelFile: 'Kullanıcı Listesi',
    created: 'Oluşturma Tarihi',
    status: 'Durum',
    fotograf: 'Fotoğraf',
  },
  ulkeler: {
    code: 'KODU',
    isim: 'İsim',
    ulke_olustur: 'Ülke Oluştur.',
    german: 'Almanca Ülke Adı',
    english: 'İngilizce Ülke adı (Opsiyonel)',
    ulke_kaydet: 'Ülke kaydet',
    ulke_duzenle: ' Düzenle',
    ExcelFile: 'Ülkeler',
    Ulkeler: 'Ülkeler',
    id: 'Ülke ID',
    de: 'Ülke (Almanca)',
    en: 'Ülke (İngilizce)',
    it: 'Ülke (İtalyanca)',
    es: 'Ülke (İspanyolca)',
    ru: 'Ülke (Rusça)',
    tr: 'Ülke (Türkçe)',
    fr: 'Ülke (Fransızca)',
  },
  Sehirler: {
    id: '#NO',
    sehir: 'Şehir',
    posta_kodu: 'Posta Kodu',
    sehir_olustur: 'Şehir ve Posta Kodu Ekle',
    sehir_kaydet: 'Şehir Kaydet',
    created: 'Oluşturma Tarihi',
    ExcelFile: 'Posta kodu ve Şehirler',
    Sehirler: 'Şehirler',
  },
  SigortaSirketleri: {
    SigortaSirketleri: 'Sigorta Şirketleri',
    id: '#NO',
    isim: 'Şirket Adı',
    olusturma_tarihi: 'Oluşturma Tarihi',
    sigorta_sirketi_olustur: 'Sigorta Şirketi Oluştur',
    sigorta_sirketi_duzenle: 'Düzenle - Sigorta Şirketi',
    sigorta_sirketi_kaydet: 'Sigorta Şirketi Kaydet',
    created: 'Oluşturma Tarihi',
    ExcelFile: 'Sigorta Şirketleri',
  },
  EvrakTipleri: {
    id: '#NO',
    isim: 'İsim',
    aciklama: 'Açıklama',
    olusturma_tarihi: 'Oluşturma Tarihi',
    evrak_olustur: 'Yeni Evrak tipi',
    evrak_kaydet: 'KAYDET',
    evrak_gurubu: 'Evrak Grubu',
    evrak_tipleri: 'Evrak Tipleri',
    ExcelFile: 'Evrak Tipleri',
    grup: 'Evrak Grubu',
    created: 'Oluşturma Tarihi',
  },
  MeslekGruplari: {
    MeslekGruplari: 'Meslek grupları',
    id: '#NO',
    meslek: 'Meslek',
    aciklama: 'Açıklama',
    olusturma_tarihi: 'Oluşturma Tarihi',
    Meslek_olustur: 'Yeni meslek grubu oluşturun.',
    Meslek_duzenle: 'Mesleğini Düzenle',
    Meslek_kaydet: 'KAYDET',
    ExcelFile: 'Meslek Grupları',
    created: 'Oluşturma Tarihi',
  },
  Uyruklar: {
    id: '#NO',
    uyruk: 'Uyruk',
    olusturma_tarihi: 'Oluşturma Tarihi',
    Uyruk_olustur: 'Yeni kişi uyruğu kaydedin.',
    Uyruk_duzenle: 'Düzenle',
    Uyruk_kaydet: 'UYRUK KAYDET',
    Uyruklar: 'Uyruklar',
    created: 'Oluşturma Tarihi',
    ExcelFile: 'Uyruklar',
  },
  Personeller: {
    personel_no: 'PERSONEL NO',
    isim: 'İSİM',
    soyisim: 'SOYİSİM',
    dogum_tarihi: 'DOĞUM TARİHİ',
    dogum_yeri: 'DOĞUM YERİ',
    posta_kodu_id: 'P.KODU VE ŞEHİR',
    sehir: 'P.KODU VE ŞEHİR',
    ulke_id: 'ÜLKE',
    cadde: 'CADDE',
    meslek_id: 'MESLEK',
    ise_giris_tarihi: 'İŞE GİRİŞ TARİHİ',
    sigorta_sirketi_id: 'SİGORTA ŞİRKETİ',
    kimlik_no: 'KİMLİK NO',
    sosyal_guvenlik_no: 'S.G. NO',
    uyruk_id: 'UYRUK',
    telefon: 'TELEFON',
    kimlik_seri_no: 'KİMLİK SERİ NO',
    kimlik_gecerlilik_tarihi: 'KİMLİK G. TARİHİ',
    pasaport_no: 'PASAPORT NO',
    pasaport_gecerlilik_tarihi: 'PASAPORT G. TARİHİ',
    oturum_izin_no: 'OTURUM İZİN NO',
    oturum_izin_tarihi: 'OTURUM SON. TARİH',
    eposta: 'EPOSTA',
    guvenlik_belgesi: 'GUVENLIK BELGESİ',
    created: 'OLUŞTURMA TARİHİ',
    olustur: 'Personel Kayıt Formu',
    kaydet: 'KAYDET',
    kisisel: 'Kişisel bilgi',
    adres: 'Adres bilgi',
    resmi: 'Resmi',
    sonraki: 'Sonraki ·',
    posta_kodu_ve_sehir: 'ŞEHİR VE POSTA KODU',
    DetayButtonTooltip: 'Kişi kartını görüntülemek için tıklayınız.',
    DosyalarButtonTooltip: 'Dosyaları görmek ve yüklemek için tıklayınız.',
    islem: 'İŞLEMLER',
    Personellistesi: 'Personeller',
    EditToolTip: 'Düzenle',
    PersonelExcelFileName: 'Personeller',
    id: '#ID',
    ulke_de: 'ÜLKE (Almanca)',
    ulke_en: 'ÜLKE (İngilizce)',
    uyruk: 'UYRUK',
    sigorta_sirketi: 'SİGORTA ŞİRKETİ',
    posta_kodu: 'POSTA KODU',
    meslek: 'MESLEK',
    SEC: 'Personeller. (Seçim Yapılmaz ise Tümü işlenir.)',
  },
  Sozlesmeler: {
    id: '#ID',
    isim: 'İSİM',
    olusturma_tarihi: 'OLUŞTURMA TARİHİ',
    Sozlesmeler: 'Sözleşmeler',
    ExcelFile: 'Sözleşmeler',
    olustur: 'Sözleşme Oluştur',
    kaydet: 'KAYDET',
    duzenle: 'Düzenle',
    soyisim: 'SOYİSİM',
    meslek: 'MESLEK',
    firma: 'FİRMA',
    baslama_tarihi: 'BAŞ. TARİHİ',
    imza_tarihi: 'İMZA TARİHİ',
    ucret_grubu: 'ÜCRET GRUBU',
    firma_id: 'Firma ID',
    saat_ucreti: 'SAAT ÜCRETİ',
    ek_ucret: 'EK ÜCRET',
    created: 'OLUŞTURMA TARİHİ',
    firma_cadde: 'FİRMA CADDE',
    personel_id: 'PERSONEL ID',
    firma_sehir: 'FİRMA ŞEHİR',
    personel_sec: 'PERSONEL',
    firma_sec: 'FİRMA',
    islem: 'İŞLEMLER',
    personel_cadde: 'PERSONEL CADDE',
    personel_postakodu: 'PERSONEL P.KODU',
    personel_sehir: 'PERSONEL ŞEHİR',
  },
  Musteriler: {
    Iptal: 'İptal',
    Guncelle: 'GÜNCELLE',
    Musteri: 'Müşteri',
    Subeler: 'Şubeler',
    SubeEkle: 'Şube Ekle',
    kod: 'KOD',
    id: '#ID',
    isim: 'İSİM',
    adi: 'İSİM',
    status: 'Durum',
    cadde_nr: 'Cadde',
    postakodu_sehir: 'Postakodu Şehir',
    musteri_id: '#ID',
    default: 'Varsayılan',
    adresID: 'Adres ID',
    cadde: 'Cadde',
    postakodu: 'Plz Ort',
    ulke: 'Ülke',
    olusturma_tarihi: 'OLUŞTURMA TARİHİ',
    Musteriler: 'Müsteriler',
    ExcelFile: 'Müşteriler',
    olustur: 'Müşteri Oluştur',
    kaydet: 'KAYDET',
    duzenle: 'Düzenle',
    soyisim: 'SOYİSİM',
    meslek: 'MESLEK',
    firma: 'FİRMA',
    baslama_tarihi: 'BAŞ. TARİHİ',
    imza_tarihi: 'İMZA TARİHİ',
    ucret_grubu: 'ÜCRET GRUBU',
    firma_id: 'Firma ID',
    saat_ucreti: 'SAAT ÜCRETİ',
    ek_ucret: 'EK ÜCRET',
    created: 'OLUŞTURMA TARİHİ',
    firma_cadde: 'FİRMA CADDE',
    personel_id: 'PERSONEL ID',
    firma_sehir: 'FİRMA ŞEHİR',
    personel_sec: 'PERSONEL',
    firma_sec: 'FİRMA',
    islem: 'İŞLEMLER',
    personel_cadde: 'PERSONEL CADDE',
    personel_postakodu: 'PERSONEL P.KODU',
    personel_sehir: 'PERSONEL ŞEHİR',
    ucretlendirme_grup_id: 'ÜCRET GRUBU',
    EN_FAZLA_3_KARAKTER: 'En fazla 3 karakter olabilir.',
  },
  Malzemeler: {
    id: '#ID',
    isim: 'İSİM',
    cadde: 'Cadde',
    postakodu: 'Plz Ort',
    ulke: 'Ülke',
    olusturma_tarihi: 'OLUŞTURMA TARİHİ',
    Malzemeler: 'Malzemeler',
    ExcelFile: 'Sözleşmeler',
    olustur: 'Sözleşme Oluştur',
    kaydet: 'KAYDET',
    duzenle: 'Düzenle',
    soyisim: 'SOYİSİM',
    meslek: 'MESLEK',
    firma: 'FİRMA',
    baslama_tarihi: 'BAŞ. TARİHİ',
    imza_tarihi: 'İMZA TARİHİ',
    ucret_grubu: 'ÜCRET GRUBU',
    firma_id: 'Firma ID',
    saat_ucreti: 'SAAT ÜCRETİ',
    ek_ucret: 'EK ÜCRET',
    created: 'OLUŞTURMA TARİHİ',
    firma_cadde: 'FİRMA CADDE',
    personel_id: 'PERSONEL ID',
    firma_sehir: 'FİRMA ŞEHİR',
    personel_sec: 'PERSONEL',
    firma_sec: 'FİRMA',
    islem: 'İŞLEMLER',
    personel_cadde: 'PERSONEL CADDE',
    personel_postakodu: 'PERSONEL P.KODU',
    personel_sehir: 'PERSONEL ŞEHİR',
  },
  ERROR: {
    ERR_GEREKLI: 'Boş olamaz.',
    ERR_COKKISA_1: 'En az 1 Karakter olmalı.!',
    ERR_COKKISA_2: 'En az 2 Karakter olmalı.!',
    ERR_COKKISA_3: 'En az 3 Karakter olmalı.!',
    ERR_COKKISA_4: 'En az 4 Karakter olmalı.!',
    ERR_COKKISA_5: 'En az 5 Karakter olmalı.!',
    ERR_COKKISA_6: 'En az 6 Karakter olmalı.!',
    ERR_COKKISA_7: 'En az 7 Karakter olmalı.!',
    ERR_COKKISA_8: 'En az 8 Karakter olmalı.!',
    ERR_COKKISA_9: 'En az 9 Karakter olmalı.!',
    ERR_COKKISA_10: 'En az 10 Karakter olmalı.!',
    ERR_COKKISA_11: 'En az 11 Karakter olmalı.!',
    ERR_COKKISA_12: 'En az 12 Karakter olmalı.!',
    ERR_COKKISA_13: 'En az 13 Karakter olmalı.!',
    ERR_COKKISA_14: 'En az 14 Karakter olmalı.!',
    ERR_COKKISA_15: 'En az 15 Karakter olmalı.!',
    ERR_COKKISA_16: 'En az 16 Karakter olmalı.!',
    ERR_COKKISA_17: 'En az 17 Karakter olmalı.!',
    ERR_COKKISA_18: 'En az 18 Karakter olmalı.!',
    ERR_COKKISA_19: 'En az 19 Karakter olmalı.!',
    ERR_COKKISA_20: 'En az 20 Karakter olmalı.!',
    HATA: 'HATA',
    BASARILI: 'Başarılı!',
    kullanici_adi: 'Kullanıcı Adı',
    sifre: 'Şifre',
    isim: 'İsim',
    soyisim: 'Soyisim',
    ERR_KULLANICI_BULUNAMADI: 'Kullanıcı Bulunamadı.',
    MSG_KULLANICI_SILINDI: 'Kullanıcı başarıyla silindi.',
    ERR_GUNCELLEME_YAPILAMADI: 'Güncelleme işlemi başarısız oldu.',
    MSG_GUNCELLENDI: 'Güncelleme başarılı.',
    MSG_KULLANICI_OLUSTURULDU: 'Kullanıcı Oluşturuldu.',
    ERR_SUNUCU_HATASI: 'Sunucu Hatası.',
    MSG_LISTELEME_BASARILI: 'Listeleme başarılı.',
    ERR_KULLANICI_MEVCUT: 'Kullanıcı zaten mevcut',
    ERR_MESLEK_MEVCUT: 'Meslek grubu mevcut.',
    MSG_MESLEK_OLUSTURULDU: 'Başarılı şekilde oluşturuldu',
    ERR_MESLEK_BULUNAMADI: 'Meslek grubu bulunamadı.',
    MSG_MESLEK_SILINDI: 'Başarılı şekilde sistemden silindi',
    ERR_ULKE_MEVCUT: 'Ülke zaten var.',
    MSG_ULKE_OLUSTURULDU: 'Ülke oluşturma işlemi başarılı.',
    ERR_ULKE_BULUNAMADI: 'Ülke bulunamadı',
    ERR_ULKE_SILINEMEZ: 'Ülke silme işlemi kapalı.',
    ERR_POSTA_KODU_MEVCUT: 'Girdiğiniz posta kodu zaten kullanımda.',
    MSG_SEHIR_OLUSTURULDU: 'Şehir başarılı şekilde kaydedildi',
    ERR_SEHIR_BULUNAMADI: 'Şehir bulunamadı.',
    ERR_SEHIR_SILINEMEZ: 'Sistem üzerinde Şehir silme işlemi kapalı.',
    MSG_FATURA_OLUSTURULDU: 'Fatura başarılı şekilde kaydedildi',
    ERR_FATURA_BULUNAMADI: 'Fatura bulunamadı.',
    ERR_FATURA_SILINDI: 'Fatura ve Detayları Silindi.',
    ERR_FATURA_SILINEMEDI: 'Fatura Silinemedi ..!',
    ERR_SIGORTA_SIRKETI_MEVCUT: 'Sigorta şirketi zaten var',
    MSG_SIGORTA_SIRKETI_OLUSTURULDU: 'Sigorta Şirketi Oluşturuldu.',
    ERR_SIGORTA_SIRKETI_BULUNAMADI: 'Sigorta Şirketi bulunamadı.',
    ERR_SIGORTA_SIRKETI_SILINDI: 'Sigorta şirketi silindi.',
    ERR_EVRAK_TIPI_MEVCUT: 'Evrak Tipi Mevcut.',
    ERR_EVRAK_TIPI_OLUSTURULDU: 'Evrak tipi başarılı şekilde kaydedildi.',
    ERR_EVRAK_TIPI_BULUNAMADI: 'Evrak tipi bulunamadı.',
    ERR_EVRAK_TIPI_SILINDI: 'Evrak tipi silindi',
    ERR_EVRAK_TIPI_SILINEMEDI: 'Evrak tipi Silinemedi ..!',
    ERR_UYRUK_MEVCUT: 'Uyruk zaten mevcut.',
    MSG_UYRUK_OLUSTURULDU: 'Uyruk kaydı yapıldı',
    ERR_UYRUK_BULUNAMADI: 'Uyruk bulunamadı.',
    MSG_UYRUK_SILINDI: 'Uyruk silindi.',
    dogum_tarihi: 'Doğum Tarihi',
    personel_no: 'Personel No',
    MSG_PERSONEL_OLUSTURULDU: 'PERSONEL KAYDI BAŞARILI ŞEKİLDE YAPILDI!',
    MSG_DOSYA_YUKLEME_BASARILI: 'DOSYA BAŞARILI ŞEKİLDE YÜKLENDİ.',
    ERR_DOSYA_UZANTISI_GECERSIZ: 'DOSYA TİPİ GEÇERSİZ.',
    ERR_DOSYA_TASIMA_HATASI: 'DOSYA YÜKLEME ESNASINDA SUNUCU HATASI OLUŞTU.',
    ERR_DOSYA_BOYUTU_COK_FAZLA: 'DOSYA BOYUTU İZİN VERİLEN DEĞERİN ÜZERİNDE.',
    ERR_PERSONEL_KLASOR_OLUSTURULAMIYOR: 'KLASÖR OLUŞTURMA SORUNU SUNUCU HATASI.',
    ERR_UPLOAD_KLASORU_YOK: 'KLASÖR OLUŞTURMA SORUNU SUNUCU HATASI.',
    ERR_PERSONEL_NO_GEREKLI: 'PERSONEL NUMARASI GEREKLI',
    ERR_BURALAR_HENUZ_COK_ISSIZ: 'DOSYA LISTESI BOŞ',
    ERR_DOSYA_SILINDI: 'Dosya sistemden silindi.',
    ERR_DOSYA_SILINDI_DATA_SILINEMEDI: 'Dosya kaldırıldı fakat tablodan silinemedi.',
    ERR_DOSYA_SILINEMEDI: 'Dosya Silme işlemi başarısız.',
    ERR_DOSYA_BULUNAMADI: 'Dosya bulunamadı.',
    firma_id: 'FİRMA',
    personel_id: 'PERSONEL',
    baslama_tarihi: 'BAŞLAMA TARİHİ',
    imza_tarihi: 'İMZA TARİHİ',
    MSG_SOZLESME_OLUSTURULDU: 'SÖZLEŞME OLUŞTURULDU.',
    MSG_PERSONEL_SILINDI: 'PERSONEL SİLME İŞLEMİ BAŞARILI.',
    ERR_PERSONEL_MEVCUT: 'Personel Numarası zaten kullanımda.',
    ERR_EVRAK_TIPI_ZATEN_KULLANILIYOR: 'Evrak Tipi Kullanımda olduğundan silinemedi',
    MSG_MUSTERI_OLUSTURULDU: 'Müşteri kaydetme işlemi başarılı.',
    MSG_MUSTERI_SILINDI: 'Müşteri silindi.',
    ERR_MUSTERI_SILINEMEDI: 'Müşteri silme işlemi yapılırken hata oluştu.',
    MSG_ADRES_OLUSTURULDU: 'Müşteri için yeni adres eklendi.',
    MSG_ADRES_GUNCELLENDI: 'Müşteri adresi başarılı şekilde güncellendi.',
    ERR_GOREV_PLANI_OLUSTURULDU: 'Personel Görevlendirmesi yapıldı.!',
    ERR_GOREV_PLANI_SILINDI: 'Gorev Planı Silindi.',
    adres_id: 'ADRES',
    baslama_saati: 'BAŞLAMA SAATİ',
    bitis_saati: 'BİTİŞ SAATİ',
    bitis_tarihi: 'BİTİŞ TARİHİ',
    fatura_tarihi: 'FATURA TARİHİ',
    musteri_id: 'MÜŞTERİ',
    personel: 'PERSONEL',
  },
  SIL: {
    SILMEK_ISTEDINIZE_EMINMISINIZ: 'Silmek İstediğinize Eminmisiniz ?',
  },

  // Vuetify Dil dosyaları
  $vuetify: {
    badge: 'rozet',
    close: 'Kapat',
    dataIterator: {
      noResultsText: 'Eşleşen veri bulunamadı',
      loadingText: 'Yükleniyor... Lütfen bekleyin.',
    },
    dataTable: {
      itemsPerPageText: 'Sayfa başına satır:',
      ariaLabel: {
        sortDescending: 'Z den A ya sıralı.',
        sortAscending: 'A dan Z ye sıralı.',
        sortNone: 'Sıralı değil. ',
        activateNone: 'Sıralamayı kaldırmak için etkinleştir.',
        activateDescending: 'Z den A ya sıralamak için etkinleştir.',
        activateAscending: 'A dan Z ye sıralamak için etkinleştir.',
      },
      sortBy: 'Sırala',
    },
    dataFooter: {
      itemsPerPageText: 'Sayfa başına satır:',
      itemsPerPageAll: 'Hepsi',
      nextPage: 'Sonraki sayfa',
      prevPage: 'Önceki sayfa',
      firstPage: 'İlk sayfa',
      lastPage: 'Son sayfa',
      pageText: '{0} - {1} arası, Toplam: {2} kayıt',
    },
    datePicker: {
      itemsSelected: '{0} öge seçildi',
      nextMonthAriaLabel: 'Gelecek ay',
      nextYearAriaLabel: 'Gelecek yıl',
      prevMonthAriaLabel: 'Geçtiğimiz ay',
      prevYearAriaLabel: 'Geçen yıl',
    },
    noDataText: 'Bu görünümde veri yok.',
    carousel: {
      prev: 'Önceki görsel',
      next: 'Sonraki görsel',
      ariaLabel: {
        delimiter: 'Galeri sayfa {0} / {1}',
      },
    },
    calendar: {
      moreEvents: '{0} tane daha',
    },
    fileInput: {
      counter: '{0} dosya',
      counterSize: '{0} dosya (toplamda {1})',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Sayfalandırma Navigasyonu',
        next: 'Sonraki sayfa',
        previous: 'Önceki sayfa',
        page: 'Sayfaya git {0}',
        currentPage: 'Geçerli Sayfa, Sayfa {0}',
      },
    },
    rating: {
      ariaLabel: {
        icon: 'Rating {0} of {1}',
      },
    },
  },

  // Vuetify Dil Dosyası Sonu
}
