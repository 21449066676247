// eslint-disable-next-line import/no-cycle
import api from '../boot/axios'
// eslint-disable-next-line import/no-cycle,no-unused-vars
import router from '../router'

export default {
  namespaced: true,
  state: {
    arama: '',
    Musteriler: [],
    Musteri: null,
    MusteriAdresleri: [],
  },
  mutations: {
    MusterilerTanimla(state, data) {
      state.Musteriler = data
    },
    MusteriAdresleriTanimla(state, data) {
      state.MusteriAdresleri = data
    },
    MusteriAdresleriYeniTanimla(state, data) {
      state.MusteriAdresleri.push(data)
    },
    MusteriTanim(state, data) {
      state.Musteri = data
    },
    MusterilerEkle(state, data) {
      state.Musteriler.push(data)
    },
    MusteriSilState(state, id) {
      const index = state.Musteriler.findIndex(item => item.id === id)
      state.Musteriler.splice(index, 1)
    },
  },
  actions: {
    MusterilerListesi({ commit }) {
      // eslint-disable-next-line consistent-return
      api.get('musteriler').then(response => {
        if (response?.data?.result) commit('MusterilerTanimla', response?.data?.result)
        // eslint-disable-next-line consistent-return
      })
    },
    MusteriAdresleri({ commit }, data) {
      // eslint-disable-next-line consistent-return
      api.get(`adresler/${data.id}`).then(response => {
        if (response?.data?.result) commit('MusteriAdresleriTanimla', response?.data?.result)
        // eslint-disable-next-line consistent-return
      })
    },

    MusteriAdresleriTumu({ commit }, data) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line consistent-return
        api.get(`adresler/${data.id}`)
          .then(response => {
            if (response?.data?.result) commit('MusteriAdresleriTanimla', response?.data?.result)
            // eslint-disable-next-line consistent-return
            resolve(response)
          },
          error => {
            reject(error)
          })
      })
    },

    MusteriAdresleriEkle({ commit }, data) {
      // eslint-disable-next-line consistent-return
      api.post('adresler', data).then(response => {
        if (response?.data?.result) commit('MusteriAdresleriYeniTanimla', response?.data?.result)
        // eslint-disable-next-line consistent-return
      })
    },
    TekMusteri({ commit }, data) {
      // eslint-disable-next-line consistent-return
      api.get(`musteriler/${data.id}`).then(response => {
        if (response?.data?.result) commit('MusteriTanim', response?.data?.result)
        // eslint-disable-next-line consistent-return
      })
    },
    MusterilerOlustur({ commit }, data) {
      // eslint-disable-next-line consistent-return
      api.post('musteriler', data).then(response => {
        // eslint-disable-next-line consistent-return
        if (response?.data?.code === 201) {
          commit('MusterilerEkle', response.data.result)
        }
      })
    },

    // eslint-disable-next-line no-empty-pattern
    MusterilerGuncelle({ }, data) {
      return new Promise((resolve, reject) => {
        api.patch(`musteriler/${data.id}`, data)
          .then(response => {
            resolve(response)
          },
          error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    AdresGuncelle({ }, data) {
      return new Promise((resolve, reject) => {
        api.patch(`adresler/${data.id}`, data)
          .then(response => {
            resolve(response)
          },
          error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    MusterilerSil({ commit }, data) {
      return new Promise((resolve, reject) => {
        api.delete(`musteriler/${data.id}`, data)
          .then(response => {
            commit('MusteriSilState', data.id)
            resolve(response)
          },
          error => {
            reject(error)
          })
      })
    },
  },
}
