<template>
    <v-main>
      <router-view></router-view>
    </v-main>
</template>

<script>
export default {}
</script>

<style>
</style>
