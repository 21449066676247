<template>
  <div v-if="PageControl">
    <v-avatar
      size="35px"
      class="cursor-pointer ml-6"
      @click="Ekle = true"
    >
      <div id="profileImage">
        +
      </div>
    </v-avatar>
  </div>
  <div v-else-if="FaturaKontrol">
      <v-avatar
        size="35px"
        class="cursor-pointer ml-6"
        @click="$router.push({name:'fatura-olustur'})"
      >
        <div id="profileImage">
          +
        </div>
      </v-avatar>

  </div>


</template>

<script>

export default {
  name: 'TanimOlusturma',
  computed: {
    PageControl() {
      // eslint-disable-next-line no-prototype-builtins,eqeqeq
      //console.log(this.$route)
      //if (this.$route.meta.hasOwnProperty('UstMenuYeni')&& this.$store.state.user.yetki == 2) {
      if (this.$route.meta.hasOwnProperty('UstMenuYeni')) {
        return this.$route.meta.UstMenuYeni
      }

      return false
    },

    FaturaKontrol() {
      // eslint-disable-next-line eqeqeq
      if (this.$store.state.user.yetki == 2 && this.$route.name == 'faturalar') {
        return true
      }

      return false
    },

    Ekle: {
      get() {
        return this.$store.state.createDialog
      },
      set(bool) {
        this.$store.state.createDialog = bool
      },
    },
  },
}
</script>

<style scoped>

</style>
