// eslint-disable-next-line import/no-cycle
import api from '../boot/axios'
// eslint-disable-next-line import/no-cycle,no-unused-vars
import router from '../router'

export default {
  namespaced: true,
  state: {
    arama: '',
    Malzemeler: [],
    Malzeme: null,
  },
  mutations: {
    MalzemelerTanimla(state, data) {
      state.Malzemeler = data
    },
    MalzemeTanim(state, data) {
      state.Malzeme = data
    },
    MalzemelerEkle(state, data) {
      state.Malzemeler.push(data)
    },
  },
  actions: {
    MalzemelerListesi({ commit }) {
      // eslint-disable-next-line consistent-return
      api.get('malzemeler').then(response => {
        if (response?.data?.result) commit('MalzemelerTanimla', response?.data?.result)
        // eslint-disable-next-line consistent-return
      })
    },
    TekMalzeme({ commit }, data) {
      // eslint-disable-next-line consistent-return
      api.get(`malzemeler/${data.id}`).then(response => {
        if (response?.data?.result) commit('MalzemeTanim', response?.data?.result)
        // eslint-disable-next-line consistent-return
      })
    },
    MalzemelerOlustur({ commit }, data) {
      // eslint-disable-next-line consistent-return
      api.post('malzemeler', data).then(response => {
        // eslint-disable-next-line consistent-return
        if (response?.data?.code === 201) {
          commit('malzemelerEkle', response.data.result)
        }
      })
    },

    // eslint-disable-next-line no-empty-pattern
    MalzemelerGuncelle({ }, data) {
      return new Promise((resolve, reject) => {
        api.patch(`malzemeler/${data.id}`, data)
          .then(response => {
            resolve(response)
          },
          error => {
            reject(error)
          })
      })
    },
  },
}
