// eslint-disable-next-line import/no-cycle
import api from '../boot/axios'
// eslint-disable-next-line import/no-cycle,no-unused-vars
import router from '../router'

export default {
  namespaced: true,
  state: {
    Faturalar: [],
    Fatura: null,
    FaturaDetaylari: [],
  },
  mutations: {
    FaturaTanimla(state, data) {
      state.Faturalar = data
    },
    FaturaEkle(state, data) {
      state.Faturalar.push(data)
    },
    FaturaDetaylariTanimla(state, data) {
      state.FaturaDetaylari = data
    },
    TekliFaturaTanimla(state, data) {
      state.Fatura = data
    },
  },
  actions: {
    FaturalarListesi({ commit }) {
      // eslint-disable-next-line consistent-return
      api.get('faturalar').then(response => {
        if (response?.data?.result) commit('FaturaTanimla', response?.data?.result)
        // eslint-disable-next-line consistent-return
      })
    },
    FaturaOlustur({ commit }, data) {
      // eslint-disable-next-line consistent-return
      api.post('faturalar', data).then(response => {
        // eslint-disable-next-line consistent-return
        if (response?.data?.code === 201) {
          commit('FaturaEkle', response.data.result)
          router.push({ name: 'faturalar' })
        }
      })
    },

    FaturaGetir({ commit }, data) {
      return new Promise((resolve, reject) => {
        api.get(`fatura/${data.id}`).then(response => {
            resolve(response)
            if (response?.data?.code === 200) {
              commit('TekliFaturaTanimla', response?.data?.result)
            }
          },
          error => {
            reject(error)
          })
      })
    },

    FaturaDetayiGetir({ commit }, data) {
      // eslint-disable-next-line consistent-return
      api.get(`fatura-detaylari/${data.id}`).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response?.data?.code == 200) {
          return response?.data?.result
        }
        // eslint-disable-next-line consistent-return
      })
    },
    FaturaSil({ state }, data) {
      return new Promise((resolve, reject) => {
        api.delete(`fatura-sil/${data.id}`, data)
          .then(response => {
            if (response.data.status) {
              const index = state.Faturalar.findIndex(e => e.id === data.id)
              // eslint-disable-next-line no-unused-vars
              const veri = state.Faturalar.splice(index, 1)
              resolve(response)
            }
            // eslint-disable-next-line no-undef
          },
          error => {
            reject(error)
          })
      })
    },

    // eslint-disable-next-line no-empty-pattern
    FaturaGuncelle({ }, data) {
      return new Promise((resolve, reject) => {
        api.patch(`faturalar/${data.id}`, data)
          .then(response => {
            resolve(response)
          },
          error => {
            reject(error)
          })
      })
    },
  },
}
