import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login',
  },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: () => import('@/views/dashboard/Dashboard.vue'),
  //   meta: {
  //     role: '1',
  //   },
  // },

  // {
  //   path: '/ulkeler',
  //   name: 'ulkeler',
  //   component: () => import('@/views/ulkeler/ulkeler.vue'),
  //   meta: {
  //     role: '1',
  //     UstMenuYeni: true,
  //   },
  // },

  // {
  //   path: '/sehirler',
  //   name: 'sehirler',
  //   component: () => import('@/views/sehirler/sehirler.vue'),
  //   meta: {
  //     role: '1',
  //     UstMenuYeni: true,
  //   },
  // },

  // {
  //   path: '/sigorta-sirketleri',
  //   name: 'sigorta-sirketleri',
  //   component: () => import('@/views/sigorta-sirketleri/sigorta-sirketleri.vue'),
  //   meta: {
  //     role: '1',
  //     UstMenuYeni: true,
  //   },
  // },
  {
    path: '/sozlesmeler',
    name: 'sozlesmeler',
    component: () => import('@/views/sozlesmeler/sozlesmeler.vue'),
    meta: {
      role: '1',
      UstMenuYeni: true,
    },
  },

  {
    path: '/sozlesme/:id',
    name: 'sozlesme_print',
    component: () => import('@/views/sozlesmeler/sozlesme-print.vue'),
    meta: {
      layout: 'print',
      role: '1',
      UstMenuYeni: true,
    },
  },

  // {
  //   path: '/fatura-pdf',
  //   name: 'fatura_pdf',
  //   component: () => import('@/views/faturalar/fatura-pdf.vue'),
  //   meta: {
  //     role: '1',
  //     UstMenuYeni: true,
  //   },
  // },

  {
    path: '/evrak-tipleri',
    name: 'evrak',
    component: () => import('@/views/evrak-tipleri/evrak-tipleri.vue'),
    meta: {
      role: '1',
      UstMenuYeni: true,
    },
  },
  {
    path: '/gorev-takvimi',
    name: 'gorev-takvimi',
    component: () => import('@/views/gorev-plani/gorev-takvimi.vue'),
    meta: {
      role: '1',
      UstMenuYeni: false,
    },
  },
  {
    path: '/gorev-plani',
    name: 'gorev-plani',
    component: () => import('@/views/gorev-plani/gorev-plani.vue'),
    meta: {
      role: '1',
      UstMenuYeni: true,
    },
  },

  {
    path: '/gorev-plani/manuel-guncelle/:id',
    name: 'gorev-plani-manuel-guncelle',
    component: () => import('@/views/gorev-plani/manuel-guncelle-page.vue'),
    meta: {
      role: '1',
      UstMenuYeni: true,
    },
  },

  {
    path: '/gorev-mutabakat',
    name: 'gorev-mutabakat',
    component: () => import('@/views/gorev-plani/gorev-mutabakat.vue'),
    meta: {
      role: '1',
      UstMenuYeni: true,
    },
  },

  {
    path: '/personel-listesi',
    name: 'personel-listesi',
    component: () => import('@/views/personel/personeller.vue'),
    meta: {
      role: '1',
      UstMenuYeni: true,
    },
  },

  {
    path: '/musteri-listesi',
    name: 'musteri-listesi',
    component: () => import('@/views/musteriler/musteriler.vue'),
    meta: {
      role: '1',
      UstMenuYeni: true,
    },
  },
  // {
  //   path: '/malzeme-listesi',
  //   name: 'malzeme-listesi',
  //   component: () => import('@/views/malzemeler/malzemeler.vue'),
  //   meta: {
  //     role: '1',
  //     UstMenuYeni: true,
  //   },
  // },
  {
    path: '/personel-olustur',
    name: 'personel-olustur',
    component: () => import('@/views/personel/personel-olustur.vue'),
    meta: {
      role: '2',
    },
  },
  {
    path: '/personel-duzenle/:id',
    name: 'personel-duzenle',
    component: () => import('@/views/personel/personel-duzenle.vue'),
    meta: {
      role: '2',
    },
  },
  {
    path: '/dosyalar/:id',
    name: 'dosyalar',
    component: () => import('@/views/dosyalar/dosyalar.vue'),
    meta: {
      role: '1',
    },
  },
  {
    path: '/belgeler-listesi',
    name: 'belgeler-listesi',
    component: () => import('@/views/dosyalar/dosyalar-listesi.vue'),
    meta: {
      role: '1',
    },
  },

  // {
  //   path: '/meslekler',
  //   name: 'meslek',
  //   component: () => import('@/views/meslek-gruplari/meslek-gruplari.vue'),
  //   meta: {
  //     role: '1',
  //     UstMenuYeni: true,
  //   },
  // },

  // {
  //   path: '/uyruklar',
  //   name: 'uyruklar',
  //   component: () => import('@/views/uyruklar/uyruklar.vue'),
  //   meta: {
  //     role: '1',
  //     UstMenuYeni: true,
  //   },

  {
    path: '/kullanicilar',
    name: 'kullanicilar',
    component: () => import('@/views/kullanicilar/kullanici_listesi.vue'),
    meta: {
      role: '2',
      UstMenuYeni: 'kullanici',
    },
  },

  // {
  //   path: '/kullanici-olustur',
  //   name: 'kullanici-olustur',
  //   component: () => import('@/views/kullanicilar/kullanici_olustur.vue'),
  //   meta: {
  //     role: '2',
  //   },
  // },

  {
    path: '/kullanici-guncelle/:id',
    name: 'kullanici-guncelle',
    component: () => import('@/views/kullanicilar/kullanici_guncelle.vue'),
    meta: {
      role: '2',
    },
  },

  // {
  //   path: '/typography',
  //   name: 'typography',
  //   component: () => import('@/views/typography/Typography.vue'),
  //   meta: {
  //     role: '1',
  //   },
  // },

  // {
  //   path: '/icons',
  //   name: 'icons',
  //   component: () => import('@/views/icons/Icons.vue'),
  //   meta: {
  //     role: '1',
  //   },
  // },

  // {
  //   path: '/cards',
  //   name: 'cards',
  //   component: () => import('@/views/cards/Card.vue'),
  //   meta: {
  //     role: '1',
  //   },
  // },

  // {
  //   path: '/simple-table',
  //   name: 'simple-table',
  //   component: () => import('@/views/simple-table/SimpleTable.vue'),
  //   meta: {
  //     role: '1',
  //   },
  // },

  // {
  //   path: '/form-layouts',
  //   name: 'form-layouts',
  //   component: () => import('@/views/form-layouts/FormLayouts.vue'),
  //   meta: {
  //     role: '1',
  //   },
  // },

  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      role: '1',
    },
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      role: '0',
      layout: 'blank',
    },
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      role: '0',
      layout: 'blank',
    },
  },

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      role: '0',
      layout: 'blank',
    },
  },

  // {
  //   path: '/faturalar',
  //   name: 'faturalar',
  //   component: () => import('@/views/faturalar/fatura_listesi.vue'),
  //   meta: {
  //     role: '2',
  //   },
  // },

  // {
  //   path: '/fatura-olustur',
  //   name: 'fatura-olustur',
  //   component: () => import('@/views/faturalar/fatura_olustur.vue'),
  //   meta: {
  //     role: '2',
  //   },
  // },

  // {
  //   path: '/fatura-guncelle/:id',
  //   name: 'fatura-guncelle',
  //   component: () => import('@/views/faturalar/fatura_guncelle.vue'),
  //   meta: {
  //     role: '2',
  //   },
  // },

  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !Store.state.isAuthenticated) next({ name: 'login' })
  else if (to.name === 'login' && Store.state.isAuthenticated) next({ name: 'personel-listesi' })
  else next()
})
export default router
